// ref: https://automationexchange.atlassian.net/wiki/spaces/~7120202ce04b74479d43a699095e3c74aae9c1/pages/2848849983/Typography

h1 {
  @apply text-[96px] leading-[112px] font-light;
}

h2 {
  @apply text-6xl leading-[72px] font-light;

}

h3 {
  @apply text-5xl leading-[56px] font-normal;

}

h4 {
  @apply text-4xl leading-[36px] font-normal;

}

h5 {
  @apply text-2xl leading-[24px] font-normal;

}

h6 {
  @apply text-xl leading-[24px] font-medium;

}


.subtitle {
  @apply text-base leading-[24px] font-normal;

}

.subtitle-sm {
  @apply text-sm leading-[24px] font-normal;

}

.body {
  @apply text-base leading-[24px] font-normal;

}

.body-sm {
  @apply text-sm leading-[20px] font-normal;

}