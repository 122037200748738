@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_variables';
@import './_kendo-variable-overrides';
@import 'extensions';
@import './kendo-styles.scss';
@import './typography.scss';

body {
	@apply font-roboto;
	height: 100vh;
}

@layer base {
  a {
    @apply cursor-pointer underline;
  }

	ol {
		@apply list-decimal ml-8;
	}

	ul {
		@apply list-disc ml-8;
	}
}

//Auth
.auth-screen {
	@apply flex justify-between items-center flex-col h-screen bg-common-greyV3;

	kendo-formfield {
		&.k-form-field-error {
			.k-label {
				@apply text-text-primary;
			}
		}

		.field-label {
			@apply text-text-primary;
		}
	}

	.auth-form-button {
		@apply w-full rounded disabled:bg-disabled-v1 disabled:text-disabled-v2 h-9 uppercase text-sm border-0 shadow-none mt-6;
		background-color: var(--operator-background-primary);
		color: var(--operator-color-primary);
	}

	.form-actions {
		@apply flex items-center justify-between w-full mt-1;

		.form-action {
			@apply text-text-primary text-2xs cursor-pointer;
		}
	}

	.error-message-container {
		@apply bg-alerts-danger-bg-v2 rounded mt-6;
	}
	.error-message-text {
		@apply text-alerts-danger-text-v2;
	}
}

.custom-input {
	@apply px-5 py-1 border-0 border-b border-b-border-primary shadow-none focus:shadow-none focus-visible:shadow-none focus:outline-none bg-common-dark-v2 rounded-b-none;
	min-width: var(--app-size-11-3);

	&.k-input-solid.k-focus {
		@apply shadow-none;
	}
}

.dialog-cancel-button {
	@apply py-2;
}

.primary-button {
	@apply px-4 py-2 items-center flex bg-common-primary hover:bg-common-hoverPrimary shadow-primary text-text-light text-center rounded hover:no-underline text-sm font-medium uppercase  cursor-pointer justify-center disabled:bg-disabled-v1 disabled:text-common-grey;
}

.base-button {
	@apply w-full px-app-size-1-2 items-center gap-2 flex py-2 cursor-pointer bg-transparent shadow-primary text-text-primary text-center rounded hover:no-underline hover:bg-button-primary text-sm font-medium uppercase;
}
.layout-heading {
	@apply font-light text-5xl mb-0  text-text-primary leading-[3.5rem] tracking-[-0.5px] mr-8;
	font-family: Roboto, sans-serif !important;
	color: #000 !important;
}

.layout-subtext {
	@apply text-xs text-common-primary;
}

.btn-cancel {
	@apply border-border-dark border-solid border-[1px];
}

.quick-tools-button {
	@apply text-sm cursor-pointer mr-7 text-common-primary font-bold;
}

.disabled-button {
	@apply disabled:bg-border-secondary disabled:border-0 disabled:text-border-primary;
}

.export-button {
	@apply base-button border-border-dark border-solid border-[1px];
}

.export-button-sm {
	@apply border-border-dark hover:bg-button-primary border-solid border-[1px] px-2 text-sm rounded;
}

.add-customer-breadcrumbs {
	@apply text-common-primary;
}

.tab-main {
	@apply flex flex-col justify-start items-stretch h-full w-full overflow-auto;
}
.donut-chart-class {
	@apply h-app-size-18-1 w-app-size-18-1;
}
.sub-tab-main {
	@apply flex flex-col gap-4 p-4;
}

.sub-tab-heading {
	@apply text-xl font-medium text-black flex items-center gap-4;
}

.sub-tab-layout {
	@apply flex flex-col gap-4;
	.k-expander-title {
		@apply text-text-tertiary text-sm font-bold normal-case;
	}
	.sub-tab-range-main {
		@apply flex flex-col gap-4;
		.range {
			@apply flex items-center gap-app-size-3-1 justify-start;
			.tooltip-main {
				@apply flex-shrink-0 w-44;
				.tooltip-anchor {
					@apply block w-full py-app-size-0-4 bg-button-primary truncate px-4 shadow-primary text-text-primary text-center rounded hover:no-underline hover:text-text-primary text-sm font-medium uppercase;
				}
			}
		}
	}
}

.line-chart-main {
	.line-chart-header {
		@apply flex items-center gap-app-size-2-1;
		.header-text {
			@apply m-0 text-xs;
		}
		.chip {
			@apply flex items-center gap-1 p-[5px] rounded text-text-light;
			.chip-text {
				@apply m-0 text-sm;
			}
			.chip-icon {
				@apply text-text-light cursor-pointer;
			}
		}
	}
	.dd-text {
		@apply text-xs;
	}

	.report-footer {
		@apply flex items-center justify-between mt-4;

		.series-wrapper {
			@apply flex items-center gap-x-4 flex-wrap;
		}

		.series-identifier {
			@apply flex items-center gap-1.5;
			.color-bar {
				@apply w-3.5 h-1;
			}
			p {
				@apply text-sm text-text-tertiary truncate;
			}
		}

		button {
			@apply text-common-primary text-sm font-medium;
		}
	}
}

.service-tab {
	@apply w-full py-app-size-0-12 px-6;
	.service-tab-heading {
		@apply font-medium text-2xl;
	}
}

.network-panels-container {
	@apply p-4 flex flex-col gap-y-2;

	.network-panels-section-wrapper {
		@apply flex flex-col gap-y-4;
	}
	.network-panels-section-header {
		@apply flex items-center justify-between m-0 font-bold text-sm;
	}
}

.service-sub-tab {
	.sub-tab-container {
		.services-tab {
			@apply w-full max-w-full;
		}
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	body {
		font-size: 62.5%;
	}
}

.service-tab-style {
	@apply bg-button-secondary text-text-light;
}
.unassigned-tab-style {
	@apply bg-tabs-v13-dark text-text-light;
}
.range-slider-container {
	@apply w-full;
}

.iframe-page-container {
	@apply flex flex-col justify-start items-stretch gap-y-4;
	// - environment header bar height, - global search header bar height, - container padding, - (page heading height + gap)
	height: calc(100vh - 44px - 101px - 36px - 72px);
}

.iframe-container {
	@apply h-full w-full;

	iframe {
		@apply h-full w-full;
	}
}


/******  Portal 2.0 Figma Styles *****/

.card-container{
	padding: 1.5rem; /* 24px */
	background-color: #ffffff;
}
.card-header{
	font-size: 1.5rem; /* 24px */
	font-style: normal;
	font-weight: 500;
	line-height: 1.875rem; /* 125% */
}

/* . */
.page-container{
	@apply h-full flex flex-col justify-start items-stretch gap-[2rem] px-2;
}

/* . */
.section-container{
	@apply flex flex-col justify-start items-stretch gap-[1.375rem] /* 22px */;
	@apply p-[2.188rem]; /* 35px */
	background: #F2F4F7;
}

.section-container-no-background-color{
	@apply flex flex-col justify-start items-stretch gap-[1.375rem] /* 22px */;
	@apply p-[2.188rem]; /* 35px */
}


.container-row{
	@apply flex flex-row justify-start items-center;
}

/* . */
.content-container{
	@apply flex flex-col gap-[2rem]; /* 32px */
	padding: 24px;
}

// use inner-content-container for containers that arent using expansion panels
// so radius, comments + files, history
// network and tasks use expansion panels so do not need this extra container / spacing
.inner-content-container {
	@apply p-[1.5rem] bg-[#FBFBFB];
}

.grid-section-container{
	@apply h-[75vh];
}

.content-container-with-max-height{
	@apply h-[55vh];
}

//.content-container{
//	@apply flex flex-col justify-start items-stretch gap-[1rem];
//	background: #fff;
//	padding: 24px 25px 25px 25px;
//}

// Check to add min-height instead
//kendo-formfield{
//	@apply h-[5.375rem];
//}


.form-container{
	@apply flex flex-col justify-start items-stretch gap-[1.5rem];
}

/* . */
.form-row{
	@apply flex flex-row gap-[2rem];
}

.card{
	@apply flex flex-col justify-start items-stretch gap-[1.5rem];
	@apply p-[2.75rem];
	background: #fafafa;

	.card-header{
		font-family: Roboto, sans-serif;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 30px; /* 125% */
	}
}

.form-label{
	@apply w-full;
}

.form-switch-content{
	@apply items-center gap-[1rem]
}

.action-button-container{
	@apply flex flex-row gap-[1.25rem];
}

/* . */
.action-button{
	///* @apply w-[10rem]; */
 	@apply w-[11.25rem];
}

.tabstrip-container{
	@apply h-[70vh];
}
.verification-code-textbox{
	@apply w-[12rem];
}

/* . */
.fit-container{
	width:100%;
	height: 100%;
}


.one-action-button-cell {
	width: 2.5rem;
}

.two-action-button-cell {
	width: 4rem;
}

.three-action-button-cell {
	width: 8.5rem;
}

.container-labels{
  font-weight: bold;
}

.section-label-main-header{
	font-weight: bold;
	font-size: large;
}

.section-main-header-container{
	.k-expander-header{
		background-color: #272D30;
		color: white;
		.section-label-main-header{
			font-size: 1.25rem;
		}
		.section-label-main-header, .section-label-detail-header {
			color: white;
		}
	}
	.k-expander-content-wrapper{
		background-color: #F2F4F7;
		.k-expander-content{
			padding:0;
		}
	}

}




/* Client scss */

.display-text-label{
	@apply w-[20rem];
}

.field-header-row-container{
	@apply py-[16px];
}
.field-value-header{

}

.field-value-container{
	@apply flex flex-col;
}
.field-value-row-container{
	@apply py-[.625rem];
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), #FFF;
	border-bottom: 1px solid rgba(0,0,0,.12);
}
.field-value-row-container.success{

}

.field-value-row-container.warning{
	color: black;
	background: #ffc000;
}

.field-value-row-container.error{
	color: white;
	background: #f31700;
}

/* Tabstrips */
.k-tabstrip-items {
	text-transform: none;
}

.customer-tabstrip.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
	@apply w-[50%] min-w-[26.5rem];
}


.customer-tabstrip.k-tabstrip-top > .k-tabstrip-content {
	background-color:#F2F4F7;
	padding-inline:0;
}

.customer-tabstrip.k-tabstrip-top.single-item-tab > .k-tabstrip-items-wrapper .k-item {
	@apply w-[100%];
}

/* Active tabstrip */
.customer-tabstrip.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
	background-color: #272D30;
	color: white;
	border-width:0;
	z-index: 1000;
}
.customer-tabstrip.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active::after{
	border-width:0;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item{
	@apply h-[6.125rem];
	// @apply w-[7rem]; //#todo:portal2 - adjust to 128px and word-wrap
}


/* Grid */
.k-column-title{
  white-space: pre-wrap;
}
.green-chip-color{
	color: white;
  background-color: #37B400;
}

.amber-chip-color{
	color: white;
	background-color: #FFC000;
}

.red-chip-color{
	color: white;
	background-color: #F31700;
}

// kendo .k-grid class sets text decoration for child <a> elements to none
.k-grid .router-link{
	@apply cursor-pointer underline;
	color: blue;
}

.expansion-header-title {
	@apply flex flex-row justify-between items-center w-full;
}

.expansion-panel-container{
	@apply flex flex-col  gap-4 ;
}

.dialog-action-button-container{
	@apply flex flex-row items-stretch gap-4;
	border-top: solid 1px #F0F0F0;
	padding-top:1rem;
}

.busy-dialog-container{
  width: 340px;
}

.default-dialog-action-button{
	background-color: #EBEBEB;
}


.notification-container{
	@apply flex flex-row gap-[.5rem] items-center;
	padding: 1rem;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.20), 0 4px 5px 0px rgba(0, 0, 0, 0.14), 0 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.success{
  background-color: #37B400;
	color: white;
}
.error{
	background-color: #E02424;
	color: white;
}

.warning{
	background-color: #FFC000;
	color: black;
}

